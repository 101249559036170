import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  ref: "filter",
  class: "cp-flex cp-flex--align-center mr-instant-filter-wrapper cp-margin__t--11"
}
const _hoisted_2 = {
  key: 0,
  class: "mr-input-default__title"
}
const _hoisted_3 = { class: "cp-flex no-wrap" }
const _hoisted_4 = { class: "cp-flex cp-flex--justify-between cp-flex--align-center" }
const _hoisted_5 = {
  key: 0,
  class: "mr-input-default__title"
}
const _hoisted_6 = {
  key: 2,
  class: "mr-switch"
}
const _hoisted_7 = {
  key: 0,
  class: "mr-switch__label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_picker = _resolveComponent("date-picker")!
  const _component_gui_form_checkbox = _resolveComponent("gui-form-checkbox")!
  const _component_gui_switch = _resolveComponent("gui-switch")!
  const _component_form_select = _resolveComponent("form-select")!
  const _component_form_multi_select = _resolveComponent("form-multi-select")!
  const _component_form_input = _resolveComponent("form-input")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (list, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (!list.isHidden)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass([list.class, "mr-instant-filter-item"])
            }, [
              (list.name?.match('date'))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (list.title)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(list.title), 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_3, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.list, (item) => {
                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                          (item.value)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: item.key,
                                class: "cp-form__column cp-date-filter"
                              }, [
                                _createElementVNode("div", _hoisted_4, [
                                  (_openBlock(), _createBlock(_component_date_picker, {
                                    key: `datepicker_${item.key}`,
                                    data: item,
                                    onClick: ($event: any) => (_ctx.handleDate({ key: list.key, date: $event }, item.action))
                                  }, null, 8, ["data", "onClick"]))
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 256))
                    ])
                  ], 64))
                : (list.name?.match(/checkbox(_union)?/))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (list.title)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(list.title), 1))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.list, (item) => {
                        return (_openBlock(), _createBlock(_component_gui_form_checkbox, {
                          key: item.id,
                          modelValue: Boolean(item.checked),
                          label: item.name,
                          class: "cp-margin__b-no cp-desc",
                          onChange: ($event: any) => (_ctx.updateCheckbox($event, { key: list.key, action: list.action, id: item.id, name: item.name }))
                        }, null, 8, ["modelValue", "label", "onChange"]))
                      }), 128))
                    ], 64))
                  : (list.name?.match('switch'))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        (list.title)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(list.title), 1))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.list, (item) => {
                          return (_openBlock(), _createBlock(_component_gui_switch, {
                            key: item.id,
                            modelValue: Boolean(item.checked),
                            class: "mr-switch__control",
                            "onUpdate:modelValue": ($event: any) => (
                _ctx.updateCheckbox(
                  { target: { checked: $event } },
                  { key: list.key, action: list.action, id: item.id, name: item.name }
                )
              )
                          }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                        }), 128))
                      ]))
                    : (list.name?.startsWith('select'))
                      ? (_openBlock(), _createBlock(_component_form_select, {
                          key: 3,
                          modelValue: list.current,
                          label: list.title,
                          placeholder: list.placeholder,
                          items: list.list,
                          class: _normalizeClass(list.class ? list.class : 'cp-width--246'),
                          clear: list.clearAction?.length,
                          "is-search-enabled": list.isSearchEnabled,
                          filter: "",
                          onUpdate: ($event: any) => (_ctx.handleSelect($event, { key: list.key, action: list.action })),
                          onClear: ($event: any) => (_ctx.handleSelect($event, { key: list.key, action: list.clearAction ?? list.action }))
                        }, null, 8, ["modelValue", "label", "placeholder", "items", "class", "clear", "is-search-enabled", "onUpdate", "onClear"]))
                      : (list.name?.startsWith('multi_select'))
                        ? (_openBlock(), _createBlock(_component_form_multi_select, {
                            key: 4,
                            modelValue: 
              list.list?.filter((item) => {
                return item.checked;
              })
            ,
                            label: list.title,
                            items: list.list,
                            placeholder: list.placeholder,
                            class: _normalizeClass(list.class ? list.class : 'cp-width--246'),
                            disabled: list.disabled,
                            "is-search-enabled": list.isSearchEnabled,
                            "key-value": "name",
                            filter: "",
                            onUpdateItem: ($event: any) => (_ctx.handleSelect($event, { key: list.key, action: list.action }))
                          }, null, 8, ["modelValue", "label", "items", "placeholder", "class", "disabled", "is-search-enabled", "onUpdateItem"]))
                        : (list.name?.startsWith('search_string'))
                          ? (_openBlock(), _createBlock(_component_form_input, {
                              key: 5,
                              modelValue: list.value,
                              label: list.placeholder,
                              placeholder: list.placeholder,
                              class: _normalizeClass([list.class ? list.class : 'cp-width--246', "cp-margin__t--4"]),
                              labelActive: list.isOpenTitle,
                              clear: "",
                              "onUpdate:modelValue": ($event: any) => (_ctx.handleInput($event, { key: list.key, action: list.action }))
                            }, null, 8, ["modelValue", "label", "placeholder", "class", "labelActive", "onUpdate:modelValue"]))
                          : (list.name?.startsWith('search'))
                            ? (_openBlock(), _createBlock(_component_form_input, {
                                key: 6,
                                modelValue: list.value,
                                label: list.title,
                                labelActive: list.isOpenTitle,
                                maskConfig: { alias: 'percentage', rightAlign: false },
                                class: _normalizeClass(list.class ? list.class : 'cp-width--246'),
                                "onUpdate:modelValue": ($event: any) => (_ctx.handleSelect($event, { key: list.key, action: list.action }))
                              }, null, 8, ["modelValue", "label", "labelActive", "class", "onUpdate:modelValue"]))
                            : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ], 512))
}