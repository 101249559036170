
import { ref } from 'vue';

import { Component, Vue, Prop } from '@/lib/decorator';
import formValidate from '@/lib/formFactory/validate';
import { FilterEntityModel } from '@/lib/layouts/page/filter.interface';
import { debounceDecorator } from '@/lib/util/helpers';

import { GuiFormCheckbox, GuiSwitch } from '@library/gigant_ui';
import DatePicker from '@/components/form/DatePickerFilter.vue';
import FormInput from '@/components/form/Input/index.vue';
import FormSelect from '@/components/form/Select/index.vue';
import FormMultiSelect from '@/components/form/MultiSelect/index.vue';

@Component({
  components: {
    GuiFormCheckbox,
    GuiSwitch,
    FormMultiSelect,
    DatePicker,
    FormInput,
    FormSelect,
  },
  emits: ['updateList'],
})
export default class FilterInstant extends Vue {
  @Prop({ required: true }) readonly filters?: Record<string, FilterEntityModel>;

  filter = ref(null);
  filterValidate = formValidate(this.filter);

  async updateCheckbox(
    value: { target: { checked: boolean } },
    params: { key: string; action: string; id: string; name: string }
  ): Promise<void> {
    this.$store.dispatch(params.action, {
      key: params.key,
      checkbox: {
        id: params.id,
        name: params.name,
        bool: value.target.checked,
      },
    });

    await this.update();
  }

  async update(): Promise<void> {
    this.$emit('updateList');
  }

  async handleSelect(selected: Record<string, unknown>, params: { action: string; key: string }): Promise<void> {
    await this.$store.dispatch(params.action, {
      key: params.key,
      value: selected,
    });

    this.update();
  }

  @debounceDecorator(600)
  async handleInput(value: string, params: { action: string; key: string }): Promise<void> {
    await this.$store.dispatch(params.action, {
      key: params.key,
      value: value,
    });

    this.update();
  }

  async handleDate(date: { day: number; month: string; year: string }, action: string): Promise<void> {
    await this.$store.dispatch(action, date, { root: true });
    this.update();
  }
}
